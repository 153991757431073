import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';

import 'react-image-crop/dist/ReactCrop.css';
import { toast } from 'react-toastify';
import { dataURLtoFile } from '../../utils/helper';

const ReactImageCropper = ({
  open,
  setOpen,
  selectedImage,
  handleCroppedImageSubmit,
  width,
  height,
}) => {
  const [crop, setCrop] = useState({
    aspect: width / height,
    unit: '%',
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  const [image, setImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);

  const handleCrop = async () => {
    if (crop.height <= 0 && crop.width <= 0) {
      toast.warning('Please select the image portion');
      return;
    }
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const width = crop.width * scaleX;
    const height = crop.height * scaleY;
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      width,
      height,
      0,
      0,
      width,
      height,
    );
    const dataUrl = canvas.toDataURL('image/png');
    const croppedFile = dataURLtoFile(dataUrl, 'cropped-image.png');

    setCroppedImageUrl(URL.createObjectURL(croppedFile));
    handleCroppedImageSubmit({ croppedFile, croppedImageUrl });
    setOpen(false);
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
        document.body.style.overflow = 'auto';
      }}
      style={{
        content: {
          maxWidth: '700px',
          maxHeight: '700px',
          margin: '50px auto',
          textAlign: 'center',
          backgroundColor: '#ffffff',
          color: '#111111',
          borderRadius: '15px',
          zIndex: '99999',
          position: 'relative',
        },
        overlay: {
          backgroundColor: 'rgba(30, 30, 30, 0.7)',
        },
      }}
    >
      <h2>Crop the desired section</h2>
      <div
        style={{
          width: '560px',
          height: '560px',
          overflow: 'hidden',
          objectFit: 'cover',
          margin: 'auto',
        }}
      >
        <ReactCrop
          src={selectedImage}
          crop={crop}
          onChange={(percentCrop) => setCrop(percentCrop)}
          aspect={width / height}
          onImageLoaded={setImage}
          imageStyle={{
            objectFit: 'contain',
            width: '560px',
            height: '560px',
          }}
          ruleOfThirds
        />
      </div>
      <button
        className="btn save-btn"
        style={{ width: '200px' }}
        onClick={handleCrop}
      >
        Crop Image
      </button>
    </Modal>
  );
};

export default ReactImageCropper;
