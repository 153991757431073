import { Contract } from '@ethersproject/contracts';
import { Web3Provider } from '@ethersproject/providers';
import Web3 from 'web3';

import FactoryABI from './ABIs/new/Factory.json';
import MarketplaceABI from './ABIs/new/Marketplace.json';
import AuctionABI from './ABIs/new/Auction.json';
import ERC721ABI from './ABIs/new/ERC721.json';
import {
  AUCTION_ADDRESS,
  FACTORY_CONTRACT,
  MARKETPLACE_ADDRESS,
} from './contants';
import { getEvents, TX_TYPE } from './getWeb3Events';

//const Factoryaddress:any = process.env.FACTORY_ADDRESS;
//const Marketplaceaddress:any = process.env.MARKETPLACE_ADDRESS;
//const Auctionaddress:any = process.env.AUCTION_ADDRESS;

//const provider:any = UseWalletStore((state:any) => {state.provider});
//const signer:any = UseWalletStore((state:any) => {state.signer});

//const Marketplace = new Contract(Marketplaceaddress, MarketplaceABI, signer);
//const FactoryContract = new Contract(Factoryaddress, FactoryABI, signer);
//const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);

export const CreateNFT = async (payload) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const ERC721Contract = new Contract(
      payload.collectionaddress,
      ERC721ABI,
      signer,
    );

    const tx = await ERC721Contract.createToken(payload.URI);
    const ERC721Contracttx = await tx.wait();

    const { CreatedToken } = await getEvents(
      ERC721Contracttx,
      TX_TYPE.NFT,
      payload.collectionaddress,
      payload.chainId,
    );

    const result = {
      tokenid: CreatedToken.tokenId,
      creator: payload.walletAddress,
    };
    return [result, null];
  } catch (error) {
    console.log({ error });
    return [null, error];
  }
};

export const CreateMultipleNFTs = async (payload) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const ERC721Contract = new Contract(
      payload.collectionaddress,
      ERC721ABI,
      signer,
    );

    const tx = await ERC721Contract.createMultipleTokens(payload.URI);
    const ERC721Contracttx = await tx.wait();
    const { CreatedMultipleTokens } = await getEvents(
      ERC721Contracttx,
      TX_TYPE.NFT,
      payload.collectionaddress,
      payload.chainId,
    );

    const result = {
      tokenIds: CreatedMultipleTokens.tokenIds,
      creator: CreatedMultipleTokens.minter,
    };
    return [result, null];
  } catch (error) {
    console.log({ error });
    return [null, error];
  }
};

export const CreateCollection = async ({
  walletaddress,
  collectionname,
  collectionsymbol,
  royaltyfee,
  royaltyrecipient,
  chainId,
  supply,
}) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const FactoryContract = new Contract(FACTORY_CONTRACT, FactoryABI, signer);

    const Factorytxhash = await FactoryContract.createNFT(
      collectionname,
      collectionsymbol,
      royaltyfee,
      royaltyrecipient,
      0,
      walletaddress,
      supply,
    );

    const tx = await Factorytxhash.wait();

    const events = await getEvents(
      tx,
      TX_TYPE.FACTORY,
      FACTORY_CONTRACT,
      chainId,
    );

    return [events, null];
  } catch (error) {
    return [null, error];
  }
};

export const createMarketItem = async (
  walletaddress,
  collectionaddress,
  tokenId,
  price,
  duration,
  chainId,
) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Marketplaceaddress = MARKETPLACE_ADDRESS;
    const Marketplace = new Contract(
      Marketplaceaddress,
      MarketplaceABI,
      signer,
    );
    // TODO
    const ErcContract = new Contract(collectionaddress, ERC721ABI, signer);
    await ErcContract.setApprovalForAll(Marketplaceaddress, true, {
      from: walletaddress,
    });

    const weiPrice = Web3.utils.fromWei((price * 10 ** 18).toString(), 'wei');
    let listingfee = await Marketplace.getlistingFee();

    const weiListingFee = Web3.utils.fromWei(
      parseInt(listingfee).toString(),
      'wei',
    );

    const Marketplacetxhash = await Marketplace.createMarketItem(
      collectionaddress,
      tokenId,
      weiPrice,
      duration,
      {
        from: walletaddress,
        value: weiListingFee,
      },
    );
    const Marketplacetx = await Marketplacetxhash.wait();

    const { MarketItemCreated } = await getEvents(
      Marketplacetx,
      TX_TYPE.MARKETPLACE,
      MARKETPLACE_ADDRESS,
      chainId,
    );

    const result = {
      itemId: MarketItemCreated.itemId,
      owner: MarketItemCreated.owner,
      price: MarketItemCreated.price,
      seller: MarketItemCreated.seller,
      sold: MarketItemCreated.sold,
    };

    return [result, null];
  } catch (error) {
    console.log({ error });
    return [null, error];
  }
};

export const startEnglishAuction = async (
  walletaddress,
  collectionaddress,
  tokenId,
  startingbid,
  duration,
  chainId,
) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);

    const ErcContract = new Contract(collectionaddress, ERC721ABI, signer);
    await ErcContract.setApprovalForAll(Auctionaddress, true, {
      from: walletaddress,
    });

    const MarketplaceContract = new Contract(
      MARKETPLACE_ADDRESS,
      MarketplaceABI,
      signer,
    );
    let listingfee = await MarketplaceContract.getlistingFee();

    const weiStartingBid = Web3.utils.fromWei(
      (startingbid * 10 ** 18).toString(),
      'wei',
    );

    const AuctionContracttxhash = await AuctionContract.startEnglishAuction(
      collectionaddress,
      tokenId,
      weiStartingBid,
      duration,
      {
        from: walletaddress,
        value: listingfee,
      },
    );

    const AuctionContracttx = await AuctionContracttxhash.wait();

    const { englishAuctionStarted } = await getEvents(
      AuctionContracttx,
      TX_TYPE.AUCTION,
      AUCTION_ADDRESS,
      chainId,
    );

    const result = {
      bidId: parseInt(englishAuctionStarted._bidId),
      seller: englishAuctionStarted._seller,
      startingPrice: startingbid,
      duration,
    };
    return [result, null];
  } catch (error) {
    console.log(error);
    return [null, error];
  }
};

export const startDutchAuction = async (
  walletaddress,
  startingprice,
  minimumprice,
  collectionaddress,
  tokenId,
  duration,
  chainId,
) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const AuctionContract = new Contract(AUCTION_ADDRESS, AuctionABI, signer);
    const weiStartingBid = Web3.utils.fromWei(
      (startingprice * 10 ** 18).toString(),
      'wei',
    );
    const weiMinimumPrice = Web3.utils.fromWei(
      (minimumprice * 10 ** 18).toString(),
      'wei',
    );
    const ErcContract = new Contract(collectionaddress, ERC721ABI, signer);
    await ErcContract.setApprovalForAll(AUCTION_ADDRESS, true, {
      from: walletaddress,
    });

    const MarketplaceContract = new Contract(
      MARKETPLACE_ADDRESS,
      MarketplaceABI,
      signer,
    );
    let listingfee = await MarketplaceContract.getlistingFee();

    const AuctionContracttxhash = await AuctionContract.startDutchAuction(
      weiStartingBid,
      weiMinimumPrice,
      collectionaddress,
      tokenId,
      duration,
      {
        from: walletaddress,
        value: listingfee,
      },
    );

    const AuctionContracttx = await AuctionContracttxhash.wait();

    const { dutchAuctionStarted } = await getEvents(
      AuctionContracttx,
      TX_TYPE.AUCTION,
      AUCTION_ADDRESS,
      chainId,
    );

    const result = {
      bidid: dutchAuctionStarted._bidId,
      seller: dutchAuctionStarted._seller,
      startingprice: dutchAuctionStarted._startingPrice,
      minimumprice,
      duration,
    };
    return [result, null];
  } catch (error) {
    console.log({ error });
    return [null, error];
  }
};

export const BuyMarketItem = async (
  walletaddress,
  collectionaddress,
  ItemId,
  price,
  chainId,
) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Marketplaceaddress = MARKETPLACE_ADDRESS;
    const Marketplace = new Contract(
      Marketplaceaddress,
      MarketplaceABI,
      signer,
    );

    const weiPrice = Web3.utils.fromWei(
      parseInt(price * 10 ** 18).toString(),
      'wei',
    );
    const finalPrice = await Marketplace.getTotalFeeMarketPrice(
      weiPrice,
      collectionaddress,
    );
    const finalPriceTx = await finalPrice.wait();
    const weiFinalPrice = parseInt(
      finalPriceTx.events[0].args._totalPrice,
    ).toString();

    const BuyMarketplacetxhash = await Marketplace.buyMarketItem(
      collectionaddress,
      ItemId,
      {
        from: walletaddress,
        value: weiFinalPrice,
      },
    );

    const BuyMarketplacetx = await BuyMarketplacetxhash.wait();
    const events = await getEvents(
      BuyMarketplacetx,
      TX_TYPE.MARKETPLACE,
      MARKETPLACE_ADDRESS,
      chainId,
    );

    const result = {
      Buyer: events.BoughtMarketItem.buyer,
      Seller: events.BoughtMarketItem.seller,
    };
    return [result, null];
  } catch (error) {
    return [null, error];
  }
};

export const TransferNFT = async (
  receiveraddress,
  collectionaddress,
  tokenId,
) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const ERC721Contract = new Contract(collectionaddress, ERC721ABI, signer);
    const ERC721Contracttxhash = await ERC721Contract.NFTTransferFrom(
      receiveraddress,
      tokenId,
    );
    const ERC721Contracttx = await ERC721Contracttxhash.wait();
    let from, to;
    for (let i = 0; i < ERC721Contracttx.events.length; i++) {
      if (
        ERC721Contracttx.events[i].args &&
        ERC721Contracttx.events[i].args.length >= 1
      ) {
        from = ERC721Contracttx.events[i].args['from'];
        to = ERC721Contracttx.events[i].args['to'];
        tokenId = ERC721Contracttx.events[i].args['tokenId'].toNumber();
        if (tokenId && from && to) {
          break;
        }
      }
    }
    const result = {
      tokenid: tokenId,
      from: from,
      to: to,
    };

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const UpdateRoyaltyFee = async (collectionaddress, royaltyFee) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const ERC721Contract = new Contract(collectionaddress, ERC721ABI, signer);
    const ERC721Contracttxhash = await ERC721Contract.setRoyaltyFee(royaltyFee);
    const ERC721Contracttx = await ERC721Contracttxhash.wait();
    let newroyaltyfee;
    for (let i = 0; i < ERC721Contracttx.events.length; i++) {
      if (
        ERC721Contracttx.events[i].args &&
        ERC721Contracttx.events[i].args.length >= 1
      ) {
        newroyaltyfee =
          ERC721Contracttx.events[i].args['newroyaltyfee'].toNumber();
        if (newroyaltyfee) {
          break;
        }
      }
    }
    return newroyaltyfee;
  } catch (error) {
    console.log(error);
  }
};

export const GetListingFee = async () => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Marketplaceaddress = process.env.MARKETPLACE_ADDRESS;
    const Marketplace = new Contract(
      Marketplaceaddress,
      MarketplaceABI,
      signer,
    );
    const listingfee = await Marketplace.getlistingFee();
    return listingfee;
  } catch (error) {
    console.log(error);
  }
};

export const getDutchPrice = async (bidid) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = process.env.AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);
    const AuctionContracttxhash = await AuctionContract.getDutchPrice(bidid);
    const AuctionContracttx = await AuctionContracttxhash.wait();
    let BidId, Seller, CurrentPrice;
    for (let i = 0; i < AuctionContracttx.events.length; i++) {
      if (
        AuctionContracttx.events[i].args &&
        AuctionContracttx.events[i].args.length >= 1
      ) {
        BidId = AuctionContracttx.events[i].args['_bidId'].toNumber();
        CurrentPrice =
          AuctionContracttx.events[i].args['_currentPrice'].toNumber();
        if (BidId && Seller && CurrentPrice) {
          break;
        }
      }
    }
    const result = {
      bidid: BidId,
      seller: Seller,
      currentprice: CurrentPrice,
    };
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const EndDutchAuction = async (bidid, finalPrice, account, chainId) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const AuctionContract = new Contract(AUCTION_ADDRESS, AuctionABI, signer);
    // const [finalPrice, error] = await getCurrentPrice(bidid);
    // throwIfExists(error);
    const AuctionContracttxhash = await AuctionContract.EndDutchAuction(bidid, {
      from: account,
      value: finalPrice.wei.toString(),
    });

    const AuctionContracttx = await AuctionContracttxhash.wait();

    const events = await getEvents(
      AuctionContracttx,
      TX_TYPE.AUCTION,
      AUCTION_ADDRESS,
      chainId,
    );
    console.log({ events });

    let bidId;
    for (let i = 0; i < AuctionContracttx.events.length; i++) {
      if (
        AuctionContracttx.events[i].args &&
        AuctionContracttx.events[i].args.length >= 1
      ) {
        bidId = parseInt(AuctionContracttx.events[i].args[0]['_hex']);

        if (bidId) {
          break;
        }
      }
    }

    const result = {
      bidid,
      winner: account,
      winningbid: finalPrice.eth,
    };
    return [result, null];
  } catch (error) {
    return [null, error];
  }
};

export const isDutchAuctionCompleted = async (Bidid) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = process.env.AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);
    const DutchAuctionCompleted = await AuctionContract.isDutchAuctionCompleted(
      Bidid,
    );
    return DutchAuctionCompleted;
  } catch (error) {
    console.log(error);
  }
};
export const getCurrentPrice = async (bidid, address) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);

    const finalPrice = await AuctionContract.getTotalPriceAuction(bidid, 0, 1, {
      from: address,
    });

    const finalPriceTx = await finalPrice.wait();
    let result;
    for (let i = 0; i < finalPriceTx.events.length; i++) {
      if (
        finalPriceTx.events[i].args &&
        finalPriceTx.events[i].args.length >= 3
      ) {
        result = parseInt(finalPriceTx.events[i].args[2]['_hex']);
        if (result) {
          break;
        }
      }
    }

    const res = {
      wei: result,
      eth: (parseInt(result) * 10 ** -18).toFixed(5),
    };

    return [res, null];
  } catch (error) {
    return [null, error];
  }
};
export const bidEnglishAuction = async (bidid, bidamount, address, chainId) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);

    const weiPrice = Web3.utils.fromWei(
      parseInt(bidamount * 10 ** 18).toString(),
      'wei',
    );

    const finalPrice = await AuctionContract.getTotalPriceAuction(
      bidid,
      1,
      weiPrice,
      { from: address },
    );

    const finalPriceTx = await finalPrice.wait();

    const weiFinalPrice = parseInt(
      finalPriceTx.events[0].args._finalPrice,
    ).toString();

    const AuctionContracttxhash = await AuctionContract.bidEnglishAuction(
      bidid,
      weiPrice,
      { from: address, value: weiFinalPrice },
    );

    const AuctionContracttx = await AuctionContracttxhash.wait();

    const { englishAuctionBidSuccess } = await getEvents(
      AuctionContracttx,
      TX_TYPE.AUCTION,
      AUCTION_ADDRESS,
      chainId,
    );

    const result = {
      bidid: englishAuctionBidSuccess._bidId,
      currenthighestbidder: englishAuctionBidSuccess._currentHighestBidder,
      currenthighestbid: Web3.utils.fromWei(
        parseInt(englishAuctionBidSuccess._currentHighestBid).toString(),
        'ether',
      ),
    };

    return [result, null];
  } catch (error) {
    console.log({ error });
    return [null, error];
  }
};

export const withDrawBidValue = async (bidid) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = process.env.AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);
    const withdrawbidvalue = await AuctionContract.withDrawBidValue(bidid);
    return withdrawbidvalue;
  } catch (error) {
    console.log(error);
  }
};

export const endEnglishAuction = async (Bidid, chainId) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const AuctionContract = new Contract(AUCTION_ADDRESS, AuctionABI, signer);
    const AuctionContracttxhash = await AuctionContract.endEnglishAuction(
      Bidid,
    );
    const AuctionContracttx = await AuctionContracttxhash.wait();

    const { englishAuctionEnd } = await getEvents(
      AuctionContracttx,
      TX_TYPE.AUCTION,
      AUCTION_ADDRESS,
      chainId,
    );

    const result = {
      bidid: englishAuctionEnd._bidId,
      winner: englishAuctionEnd._winner,
      winningbid: englishAuctionEnd._winningBid,
    };
    return [result, null];
  } catch (error) {
    return [null, error];
  }
};

export const getHighestBid = async (bidid) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = process.env.AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);
    const gethighestbid = await AuctionContract.getHighestBid(bidid);
    return gethighestbid;
  } catch (error) {
    console.log(error);
  }
};

export const getTotalFeeMarketPrice = async (price, collectionaddress) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = process.env.AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);
    const gettotalfeeMarketprice = await AuctionContract.getTotalFeeMarketPrice(
      price,
      collectionaddress,
    );
    return gettotalfeeMarketprice;
  } catch (error) {
    console.log(error);
  }
};

export const getTotalPriceAuction = async (bidid, auction, bidvalue) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = process.env.AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);
    const gettotalpriceauction = await AuctionContract.getTotalPriceAuction(
      bidid,
      auction,
      bidvalue,
    );
    return gettotalpriceauction;
  } catch (error) {
    console.log(error);
  }
};

export const getFinalPrice = async (bidid, auction) => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const Auctionaddress = process.env.AUCTION_ADDRESS;
    const AuctionContract = new Contract(Auctionaddress, AuctionABI, signer);
    const getfinalprice = await AuctionContract.getFinalPrice(bidid, auction);
    return getfinalprice;
  } catch (error) {
    console.log(error);
  }
};
