import { useRef, React } from 'react';
import Navbar from '../Navbar';
import { useReducerPlus } from '../../utils/useReducerPlus';
import { handleFileIpfs } from '../../utils/IPFS/index';

// material-ui icon
import PhotoIcon from '@mui/icons-material/Photo';
import LanguageIcon from '@mui/icons-material/Language';
import InfoIcon from '@mui/icons-material/Info';
import { useWeb3React } from '@web3-react/core';

import * as BlockchainApi from '../../utils/blockchain';
import * as api from '../../utils/api';
import { throwIfExists } from '../../utils/helper';
import { toast } from 'react-toastify';
import ButtonLoader from '../ButtonLoader';
import { useNavigate } from 'react-router-dom';
import { CATEGORY, CHAIN_DATA } from '../../utils/category';
import { useDispatch, useSelector } from 'react-redux';
import { switchNetwork } from '../../utils/connection';

function CreateCollection() {
  const { account, chainId } = useWeb3React();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.authReducer.currentUser);
  const chain = useSelector((state) => state.chainReducer);

  const hiddenFileInputLogo = useRef(null);
  const hiddenFileInputFeatured = useRef(null);
  const hiddenFileInputBanner = useRef(null);

  const [state, setState] = useReducerPlus({
    logo: null,
    featured: null,
    banner: null,
    name: '',
    url: '',
    description: '',
    tags: '',
    links: '',
    address: '',
    earnings: '',
    logoURI: '',
    featuredURI: '',
    bannerURI: '',
    supply: 0,
    loader: false,
    chain: chain,
  });

  function handleClick(input_name) {
    if (input_name === 'logo') hiddenFileInputLogo.current.click();
    else if (input_name === 'featured') hiddenFileInputFeatured.current.click();
    else if (input_name === 'banner') hiddenFileInputBanner.current.click();
    // console.log(hiddenFileInput);
  }

  const handleFileUpload = async (e, input_name) => {
    const file = e.target.files[0];
    try {
      const path = await handleFileIpfs(file);
      if (path) {
        setState({
          [input_name]: file,
          [input_name + 'URI']: path,
        });
      }
    } catch (error) {
      toast.error(error.message);
      console.log('Error uploading file: ', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({ loader: true });
    try {
      if (state.chain.id !== chainId) {
        await switchNetwork(dispatch, state.chain);
      }
      if (account === user.wallet) {
        e.preventDefault();
        const {
          name,
          earnings,
          address,
          description,
          logoURI,
          featuredURI,
          bannerURI,
          url,
          links,
          category,
          supply,
        } = state;

        const [blockchainRes, blockchainError] =
          await BlockchainApi.CreateCollection({
            walletaddress: account,
            collectionname: name,
            collectionsymbol: name
              .toUpperCase()
              .split(' ')
              .map((word) => word.charAt(0))
              .join(''),
            royaltyfee: earnings,
            royaltyrecipient: address ? address : account,
            supply: supply || 100,
            chainId: state.chain.id,
          });

        throwIfExists(blockchainError);

        const payload = {
          collectionname: name,
          symbol: name
            .toUpperCase()
            .split(' ')
            .map((word) => word.charAt(0))
            .join(''),
          royaltyrecipient: address ? address : account,
          description,
          category, //todo
          logoURI,
          bannerURI,
          featuredURI,
          URL: url,
          links,
          creator_earnings: earnings,
          collectionaddress: blockchainRes.createdNFTAddress.nftcontract,
        };

        const [collectionRes, collectionError] = await api.CreateCollection(
          payload,
          account,
          state.chain.id,
        );
        throwIfExists(collectionError);
        if (collectionRes) {
          toast.success('Collection created successfully!');
          navigate('/mycollection');
        }
      } else {
        toast.error('Metamask not connected');
      }
    } catch (e) {
      console.log({ handleSubmit: e });
      toast.error(e.reason);
    } finally {
      setState({ loader: false });
    }
  };

  const handleNetworkChange = async (e) => {
    setState({ chain: JSON.parse(e.target.value) });
  };

  return (
    <>
      <div style={{ background: 'linear-gradient(#26592d, #000000)' }}>
        <div className="container">
          <Navbar />
        </div>
      </div>

      <div
        className="create-collection-form"
        // action=""
        // onSubmit={handleSubmit}
      >
        <h1>Create Collection</h1>
        <p>
          Required Fields<span style={{ color: 'red' }}>*</span>
        </p>

        {/* Logo Image */}
        <div className="image-container">
          <label htmlFor="" style={{ fontSize: '20px' }}>
            Logo Image<span style={{ color: 'red' }}>*</span>
          </label>
          <p>
            This image will also be used as navigation. 350 x 350 recommended.
          </p>
          <input
            ref={hiddenFileInputLogo}
            onChange={(e) => handleFileUpload(e, 'logo')}
            style={{ width: '0', height: '0', opacity: '0' }}
            type="file"
            name=""
            id=""
            required
          />
          <div
            className="create-collection-logo"
            onClick={() => handleClick('logo')}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!state.logo ? (
              <PhotoIcon sx={{ fontSize: 60 }} />
            ) : (
              <img
                src={window.URL.createObjectURL(state.logo)}
                style={{
                  objectFit: 'inherit',
                  objectPosition: 'center',
                  height: '100%',
                  width: '100%',
                  borderRadius: '50%',
                }}
                alt="logo-file"
              />
            )}
          </div>
        </div>

        {/* Featured Image */}
        {/* <div className="image-container">
          <label htmlFor="" style={{ fontSize: "20px" }}>
            Featured Image<span style={{ color: "red" }}>*</span>
          </label>
          <p>
            This image will be used for featuring your collection on the
            homepage, category pages, or other promotional areas of Mundum. 600
            x 400 recommended
          </p>
          <input
            ref={hiddenFileInputFeatured}
            onChange={(e) => handleFileUpload(e, "featured")}
            style={{ width: "0", height: "0", opacity: "0" }}
            type="file"
            name=""
            id=""
            required
          />
          <div
            className="create-collection-featured"
            onClick={() => handleClick("featured")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!state.featured ? (
              <PhotoIcon sx={{ fontSize: 60 }} />
            ) : (
              <img
                src={window.URL.createObjectURL(state.featured)}
                style={{
                  objectFit: "inherit",
                  objectPosition: "center",
                  height: "100%",
                  width: "100%",
                  borderRadius: "1rem",
                }}
                alt="featured-file"
              />
            )}
          </div>
        </div> */}

        {/* Banner Image */}
        <div className="image-container">
          <label htmlFor="" style={{ fontSize: '20px' }}>
            Banner Image<span style={{ color: 'red' }}>*</span>
          </label>
          <p>
            This image will appear at the top of your collection page. Avoid
            including too much text in this banner image, as the dimensions
            change on different devices. 1400 x 350 recommended.
          </p>
          <input
            ref={hiddenFileInputBanner}
            onChange={(e) => handleFileUpload(e, 'banner')}
            style={{ width: '0', height: '0', opacity: '0' }}
            type="file"
            name=""
            id=""
            required
          />
          <div
            className="create-collection-banner"
            onClick={() => handleClick('banner')}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!state.banner ? (
              <PhotoIcon sx={{ fontSize: 60 }} />
            ) : (
              <img
                src={window.URL.createObjectURL(state.banner)}
                style={{
                  objectFit: 'inherit',
                  objectPosition: 'center',
                  height: '100%',
                  width: '100%',
                  borderRadius: '1rem',
                }}
                alt="banner-file"
              />
            )}
          </div>
        </div>

        <div className="input-container">
          <label className="form-label" htmlFor="" style={{ fontSize: '20px' }}>
            Name<span style={{ color: 'red' }}>*</span>
          </label>
          <input
            className="form-control"
            onChange={handleChange}
            type="text"
            name="name"
            value={state.name}
            placeholder="Examples: Treasures of the Sea"
            required
          />
        </div>

        <div className="input-container">
          <label className="form-label" htmlFor="" style={{ fontSize: '20px' }}>
            URL
          </label>
          <p>
            Customize your URL on Mundum. Must only contain lowercase letters,
            numbers and hyphens
          </p>
          <input
            className="form-control"
            onChange={handleChange}
            value={state.url}
            type="text"
            name="url"
            placeholder="https://artnotion.io/collection/treases of the sea"
          />
        </div>

        <div className="input-container">
          <label className="form-label" htmlFor="" style={{ fontSize: '20px' }}>
            Description<span style={{ color: 'red' }}>*</span>
          </label>
          <p>
            <span style={{ color: '#0099ff' }}>Markdown</span> syntax is
            supported. 0 of 1000 characters used
          </p>
          <textarea
            className="form-control"
            placeholder="Provide a detailed description of your item"
            onChange={handleChange}
            value={state.description}
            name="description"
            id=""
            rows="5"
            required
          ></textarea>
        </div>

        <div className="input-container">
          <label htmlFor="" style={{ fontSize: '20px' }}>
            Category and Tags
          </label>
          <p>
            Make your items more discoverable on Mundum by adding tags and a
            category.
          </p>
          <select
            className="form-select"
            onChange={handleChange}
            name="category"
            aria-label="Default select example"
          >
            <option selected disabled>
              Select one item
            </option>
            {CATEGORY.map((collection, index) => (
              <option value={collection.val} key={index}>
                {collection.name}
              </option>
            ))}
          </select>
        </div>

        <div className="input-container category-info-section">
          <InfoIcon style={{ float: 'left', color: '#0099ff' }} />
          <div style={{ paddingLeft: '2rem' }}>
            <strong>Category options have been updated</strong>
            <p>
              Your category may have changed as part of this update. You can
              review and change your category and tags at any time. Up to 5 tags
              can be selected.
            </p>
          </div>
        </div>

        <div className="input-container">
          <label className="form-label" htmlFor="" style={{ fontSize: '20px' }}>
            Links
          </label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <LanguageIcon />
            </span>
            <input
              type="text"
              className="form-control"
              onChange={handleChange}
              name="links"
              placeholder="https://artnotion.io/collection/treases of the sea"
              aria-describedby="basic-addon1"
            />
          </div>
        </div>

        <div className="input-container">
          <label className="form-label" htmlFor="" style={{ fontSize: '20px' }}>
            Max Supply
          </label>
          <p>
            Customize your URL on Mundum. Must only contain lowercase letters,
            numbers and hyphens
          </p>
          <input
            className="form-control"
            onChange={handleChange}
            value={state.supply}
            type="text"
            name="supply"
            placeholder="1000"
          />
        </div>

        <div className="input-container creator-earnings">
          <label htmlFor="" style={{ fontSize: '20px' }}>
            Creator earnings
          </label>
          <p>
            Collection owners can collect creator earnings when a user re-sells
            an item they created. Contact the collection owner to change the
            collection earnings percentage or the payout address.
          </p>
          <input
            className="form-control"
            onChange={handleChange}
            type="text"
            name="address"
            id=""
            placeholder="Please enter an address"
          />
          <input
            className="form-control"
            type="number"
            onChange={handleChange}
            name="earnings"
            id=""
            placeholder="%"
            min="0"
          />
          <br />
        </div>

        <div className="input-container form-group">
          <div className="create-dropdown">
            <label htmlFor="chain">Select Chain</label>
            <select name="chain" id="chain" onChange={handleNetworkChange}>
              {CHAIN_DATA.map((chn, index) => (
                <option
                  value={JSON.stringify(chn)}
                  key={index}
                  selected={chain.id === chn.id}
                >
                  {chn.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary"
          style={{ width: '8rem', marginBlock: '2rem' }}
          disabled={state.loader}
          onClick={handleSubmit}
        >
          {state.loader ? <ButtonLoader /> : 'Create'}
        </button>
      </div>
    </>
  );
}

export default CreateCollection;
