import { InjectedConnector } from '@web3-react/injected-connector';

export const injected = new InjectedConnector({
  supportedChainIds: [1, 137, 97, 33982, 11155111, 80002],
});

export const switchNetwork = async (dispatch, chain) => {
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chain.hex }],
    });
    dispatch({
      type: 'SET_CURRENT_CHAIN',
      payload: {
        id: chain.id,
        name: chain.name,
        symbol: chain.symbol,
        hex: chain.hex,
      },
    });
  } catch (switchError) {
    console.log({ switchError });
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [chain.chainInfo],
        });
      } catch (error) {
        throw error;
      }
    }
    throw switchError;
  }
};
