import { CHAIN_DATA } from "./category";

export function shortenAddress(address) {
  if (address) {
    const prefix = address.substring(0, 6);
    const suffix = address.substring(address.length - 4, address.length);
    return `${prefix}...${suffix}`;
  }
  // return address;
}

export function throwIfExists(err) {
  if (err) {
    throw err;
  }
}

export function secondsFromNow(futureTime) {
  var now = new Date().getTime();
  var difference = new Date(futureTime) - now;
  var seconds = Math.floor(difference / 1000);
  return seconds;
}

export function fetchObjectById(id) {
  const foundObject = CHAIN_DATA.find((item) => item.id === id);
  return foundObject || null; // Return null if no object is found
}

export function ChainDetails(chainId) {
  let chain;
  switch (chainId) {
    case 137:
      chain = {
        id: chainId,
        name: "Polygon",
        symbol: "Matic",
        hex: "0x13881",
      };
      break;
    case 33982:
      chain = {
        id: chainId,
        name: "Gaura",
        symbol: "T-Gaura",
        hex: "0x84BE",
      };
      break;
    case 97:
      chain = {
        id: chainId,
        name: "TBNB",
        symbol: "T-BNB",
        hex: "0x61",
      };
      break;

    default:
      chain = {
        id: chainId,
        name: "Mumbai",
        symbol: "T-Matic",
        hex: "0x13881",
      };
  }
  return chain;
}

export const dataURLtoFile = (dataUrl, filename) => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export function getContractAddress(contractType, chainId) {
  const addresses = {
    erc471: {
      1: "0xC3577100921d73C4E003b13D9A4bbad5b2404ad9", // Ethereum mainnet
      11155111: "0xfc2535d581a6107e10457526937C94311168aaac", // Sepolia testnet
      42161: "0xC3577100921d73C4E003b13D9A4bbad5b2404ad9", // Arbitrum One
      10: "0xC3577100921d73C4E003b13D9A4bbad5b2404ad9", // OP mainnet
      137: "0xC3577100921d73C4E003b13D9A4bbad5b2404ad9", // Polgon mainnet
      8453: "0xC3577100921d73C4E003b13D9A4bbad5b2404ad9", // Base mainnet
      43114: "0xC3577100921d73C4E003b13D9A4bbad5b2404ad9", // Avalance mainnet
      56: "0x456DEF", // BNB mainnet
      80002: "0xC3577100921d73C4E003b13D9A4bbad5b2404ad9", // Amoy
    },
    marketplace: {
      1: "0x123ABC", // Ethereum mainnet
      11155111: "0x39EDe7df20AB3B5F2078e60B9a845e1363b34aDF", // Sepolia testnet
      42161: "", // Arbitrum One
      10: "", // OP mainnet
      137: "", // Polgon mainnet
      8453: "", // Base mainnet
      43114: "", // Avalance mainnet
      56: "0x456DEF", // BNB mainnet
      // 80002: "0x7767a69ee8f2a49ec8b14c77c772f726a2c465e2", // Amoy
      80002: "0xd48C10389E179d403C3A6fAc9cDe29e5b30deeB4",
    },
    auction: {
      1: "0x123ABC", // Ethereum mainnet
      11155111: "0x123ABC", // Sepolia testnet
      42161: "", // Arbitrum One
      10: "", // OP mainnet
      137: "", // Polgon mainnet
      8453: "", // Base mainnet
      43114: "", // Avalance mainnet
      56: "0x456DEF", // BNB mainnet
      // 80002: "0x7767a69ee8f2a49ec8b14c77c772f726a2c465e2", // Amoy
      80002: "0x4efa7665ba92a9aa0f35f58c0fe285240dce690f",
    },
    rpc: {
      1: `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`, // Ethereum mainnet
      11155111: `https://sepolia.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`, // Sepolia testnet
      42161: "", // Arbitrum One
      10: "", // OP mainnet
      137: "", // Polgon mainnet
      8453: "", // Base mainnet
      43114: "", // Avalance mainnet
      56: "0x456DEF", // BNB mainnet
      // 80002: "0x7767a69ee8f2a49ec8b14c77c772f726a2c465e2", // Amoy
      80002: `https://polygon-amoy.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_ID}`,
    },
  };

  if (addresses.hasOwnProperty(contractType)) {
    // Check if the chain ID is in the dictionary
    if (addresses[contractType].hasOwnProperty(chainId)) {
      return addresses[contractType][chainId];
    } else {
      return `No address found for chain ID ${chainId} for contract type ${contractType}.`;
    }
  } else {
    return `No address found for contract type ${contractType}.`;
  }
}
