import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Navbar from "../Navbar";
import * as API from "../../utils/api";
import ButtonLoader from "../ButtonLoader";
import { CHAIN_DATA } from "../../utils/category";
import { throwIfExists } from "../../utils/helper";
import { switchNetwork } from "../../utils/connection";
import * as BlockchainApi from "../../utils/blockchain";
import ReactImageCropper from "../Modals/ReactImageCropper";
import { useReducerPlus } from "../../utils/useReducerPlus";
import { handleFileIpfs, handleMetaDataIpfs } from "../../utils/IPFS/index";

const CreateNFT = () => {
  const navigate = useNavigate();

  const { account, chainId, activate } = useWeb3React();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.currentUser);
  const chain = useSelector((state) => state.chainReducer);

  const [state, setState] = useReducerPlus({
    file: null,
    name: "",
    royality: "",
    description: "",
    collection: null,
    URI: "",
    collectionData: [],
    loader: false,
    chain: chain,
  });

  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (user) {
      getUserCollection(state.chain.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.chain]);

  const getUserCollection = async (chainId) => {
    try {
      const [res, error] = await API.GetUserCollection(
        account || user.wallet,
        chainId
      );
      throwIfExists(error);
      setState({
        collectionData: res.collection.length > 0 ? res.collection : null,
        collection: res.collection[0].collection_address || null,
      });
      dispatch({ type: "UPDATE_USER_COLLECTION", payload: res.collection });
    } catch (e) {
      console.log({ getUserCollection: e });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };

  const handleNetworkChange = async (e) => {
    const { value } = e.target;
    const chain = JSON.parse(value);
    try {
      setState({ collectionData: null, chain, collection: null });
      getUserCollection(chain.id);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleCroppedImageSubmit = async (data) => {
    const { croppedFile, croppedImageUrl } = data;
    try {
      const path = await handleFileIpfs(croppedFile);
      if (path) {
        setState({
          file: croppedFile,
          URI: path,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setState({ loader: true });
    try {
      const { URI, description, collection, name, chain } = state;
      if (!collection) {
        toast.error("No collection selected");
        setState({ loader: false });
        return;
      }
      if (chain.id !== chainId) {
        await switchNetwork(dispatch, state.chain);
      }
      if (account) {
        setState({ loader: true });

        const metaObj = {
          name,
          description,
          URI,
        };

        const metaDataURI = await handleMetaDataIpfs(metaObj);

        const [tokenRes, tokenError] = await BlockchainApi.CreateNFT({
          collectionaddress: collection,
          URI: metaDataURI,
          walletAddress: account,
          chainId: chain.id,
        });

        throwIfExists(tokenError);
        const payload = {
          URI: metaDataURI,
          desc: description,
          traits: "{}",
          collectionaddress: collection,
          creator: tokenRes.creator,
          tokenId: tokenRes.tokenid,
          name,
          earnings: state.royality,
        };

        const [nftRes, nftError] = await API.CreateToken(
          account,
          payload,
          state.chain.id
        );
        throwIfExists(nftError);
        if (nftRes) {
          toast.success("Minted successfully");
          navigate(
            `/listAsset/${collection}/${tokenRes.tokenid}/${state.chain.id}`
          );
        }
      } else {
        toast.error("Metamask not connected");
      }
    } catch (error) {
      console.log({ handleSubmit: e });
      toast.error(error.message);
    } finally {
      setState({ loader: false });
    }
  };

  const [cropModal, setCropModal] = useState(false);
  return (
    <>
      <div style={{ background: "linear-gradient(#26592d, #000000)" }}>
        <div className="container">
          <Navbar />
        </div>
      </div>
      <div className="container">
        <span style={{ display: "flex", flexDirection: "column" }}>
          <form
            className="row create-nft-component"
            style={{ width: "100%" }}
            onSubmit={handleSubmit}
          >
            <div
              className="col-lg-4 overflow-hidden create-nft-image"
              style={{
                textAlign: "center",
                width: "420px",
                height: "420px",
                borderRadius: "20px",
                overflow: "hidden",
                border: "1px dotted black",
                padding: "0",
              }}
            >
              {state.file ? (
                <img
                  src={window.URL.createObjectURL(state.file)}
                  className="dotted-border-img"
                  alt="file"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                <input
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingTop: "190px",
                    paddingLeft: "40px",
                  }}
                  type="file"
                  onChange={(e) => {
                    setSrc(URL.createObjectURL(e.target.files[0]));
                    setCropModal(true);
                  }}
                  required
                />
              )}

              <ReactImageCropper
                open={cropModal}
                setOpen={setCropModal}
                selectedImage={src}
                handleCroppedImageSubmit={handleCroppedImageSubmit}
                width={1}
                height={1}
              />
            </div>
            <div className="col-lg-4">
              <div className="create-nft">
                <div className="form-group">
                  <label htmlFor="name-field">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    className="form-control"
                    id="name-field"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="royalty">Royalty (%)</label>
                  <input
                    name="royality"
                    value={state.royality}
                    onChange={handleChange}
                    type="number"
                    className="form-control"
                    id="royalty"
                    min="0"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description-field">Description</label>
                  <textarea
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                    maxLength="120"
                    className="form-control"
                    id="description-field"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="create-nft">
                <div className="form-group">
                  <label htmlFor="quantity-field">Quantity</label>
                  <input
                    type="text"
                    className="form-control"
                    id="quantity-field"
                    aria-describedby="emailHelp"
                    value={1}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <div className="create-dropdown">
                    <label htmlFor="collection">Collection</label>

                    <select
                      name="collection"
                      id="collection"
                      onChange={handleChange}
                      required
                    >
                      <option disabled selected>
                        Select collection
                      </option>
                      {state.collectionData &&
                      state.collectionData.length > 0 ? (
                        state.collectionData.map((collection, index) => (
                          <option
                            value={collection.collection_address}
                            key={index}
                          >
                            {collection.collection_name}
                          </option>
                        ))
                      ) : (
                        <option disabled selected>
                          No collection found
                        </option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <div className="create-dropdown">
                    <label htmlFor="chain">Select Chain</label>
                    <select
                      name="chain"
                      id="chain"
                      onChange={handleNetworkChange}
                    >
                      {CHAIN_DATA.map((chain, index) => (
                        <option
                          value={JSON.stringify(chain)}
                          key={index}
                          disabled={chain.id != 137}
                          selected={state.chain.id === chain.id}
                        >
                          {chain.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <button className="btn save-btn" type="submit">
              {state.loader ? <ButtonLoader /> : "Mint"}
            </button>
          </form>
        </span>
      </div>
    </>
  );
};

export default CreateNFT;
