import art from "../images/asthetic.png";
import collectibles from "../images/socratic.png";
import sports from "../images/browse.png";
import utility from "../images/cyber.png";
import threecards from "../images/threecards.png";
import virtualworld from "../images/virtualworld.png";
import domain from "../images/domain.png";

export const CATEGORY = [
  {
    name: "Arts",
    value: "Arts",
    image: art,
  },
  {
    name: "Collectibles",
    value: "Collectibles",
    image: collectibles,
  },
  {
    name: "Sports",
    value: "Sports",
    image: sports,
  },
  {
    name: "Utility",
    value: "Utility",
    image: utility,
  },
  {
    name: "Trading Cards",
    value: "Trading Cards",
    image: threecards,
  },
  {
    name: "Virtual World",
    value: "Virtual World",
    image: virtualworld,
  },
  {
    name: "Domain Names",
    value: "Domain Names",
    image: domain,
  },
];

export const CHAIN_DATA = [
  // {
  //   id: 97,
  //   name: 'Matic Testnet',
  //   hex: '0x13882',
  //   symbol: 'T_MATIC',
  //   chainInfo: {
  //     chainId: '0x13882',
  //     chainName: 'Mumbai',
  //     rpcUrls: ['https://rpc-amoy.polygon.technology'],
  //     nativeCurrency: {
  //       name: 'MATIC',
  //       symbol: 'MATIC',
  //       decimals: 18,
  //     },
  //     blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  //   },
  // },
  {
    id: 137,
    name: "Matic",
    hex: "0x89",
    symbol: "MATIC",
    chainInfo: {
      chainId: "0x89",
      chainName: "Polygon",
      rpcUrls: ["https://polygon.llamarpc.com"],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com"],
    },
  },
  {
    id: 1,
    name: "Ethereum",
    hex: "0x1",
    symbol: "ETH",
    chainInfo: {
      chainId: "0x13881",
      chainName: "Mumbai",
      rpcUrls: ["https://rpc-amoy.polygon.technology"],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    },
  },
  {
    id: 56,
    name: "Binance",
    hex: "0x38",
    symbol: "BNB",
    chainInfo: {
      chainId: "0x38",
      chainName: "Binance",
      rpcUrls: ["https://rpc.ankr.com/bsc"],
      nativeCurrency: {
        name: "Binance",
        symbol: "BNB",
        decimals: 18,
      },
      blockExplorerUrls: ["https://bscscan.com/"],
    },
  },
  // {
  //   id: 11155111,
  //   name: 'Sepolia',
  //   hex: 'AA36A7',
  //   symbol: 'ETH',
  //   chainInfo: {
  //     chainId: 'AA36A7',
  //     chainName: 'Sepolia',
  //     rpcUrls: ['https://rpc.sepolia.org'],
  //     nativeCurrency: {
  //       name: 'ETHEREUM',
  //       symbol: 'ETH',
  //       decimals: 18,
  //     },
  //     blockExplorerUrls: ['https://sepolia.etherscan.io'],
  //   },
  // },
  // {
  //   id: 97,
  //   name: 'Binance Testnet',
  //   hex: '0x61',
  //   symbol: 'BNB',
  //   chainInfo: {
  //     chainId: '0x61',
  //     chainName: 'Binance Testnet',
  //     rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  //     nativeCurrency: {
  //       name: 'Binance Testnet',
  //       symbol: 'BNB',
  //       decimals: 18,
  //     },
  //     blockExplorerUrls: ['https://explorer.binance.org/smart-testnet'],
  //   },
  // },
  // {
  //   id: 33982,
  //   name: 'Gaura Testnet',
  //   hex: '0x84BE',
  //   symbol: 'tGAURA',
  //   chainInfo: {
  //     chainId: '0x84BE',
  //     chainName: 'Gaura Testnet',
  //     rpcUrls: ['https://testnet-rpc.gaurascan.com'],
  //     nativeCurrency: {
  //       name: 'Gaura Testnet',
  //       symbol: 'tGAURA',
  //       decimals: 18,
  //     },
  //     blockExplorerUrls: ['https://testnet-explorer.gaurascan.com/'],
  //   },
  // },
];
