export const BASEAPI_PATH = "https://api-marketplace.mundum.com"; //prod
// export const BASEAPI_PATH = 'http://3.111.248.224'; //dev
// export const BASEAPI_PATH = 'http://localhost:8080'; //local

export const API_ROUTES = {
  LOGIN: BASEAPI_PATH + "/account/api/Userlogin",
  REGISTER: BASEAPI_PATH + "/account/api/UserRegister",
  CREATE_COLLECTION: BASEAPI_PATH + "/assets/api/CreateCollection",
  GET_USER_COLLECTION: BASEAPI_PATH + "/assets/api/UserAllCollections",
  GET_SINGLE_COLLECTION: BASEAPI_PATH + "/assets/api/SingleCollection",
  GET_ALL_MARKETPLACE_ASSET: BASEAPI_PATH + "/assets/api/AllAssets",
  GET_NFT_BY_CATEGORY: BASEAPI_PATH + "/assets/api/AllCollectionsofCategory",
  CREATE_ASSET: BASEAPI_PATH + "/assets/api/CreateAsset",
  CREATE_MULTIPLE_ASSET: BASEAPI_PATH + "/assets/api/CreateMultipleAssets",
  GET_SINGLE_ASSET: BASEAPI_PATH + "/assets/api/SingleAsset",
  SELL_ASSET: BASEAPI_PATH + "/assets/api/SellAsset",
  BUY_ASSET: BASEAPI_PATH + "/assets/api/BuyAssets",
  START_AUCTION: BASEAPI_PATH + "/assets/api/startAuction",
  END_AUCTION: BASEAPI_PATH + "/assets/api/EndAuction",
  USER_ASSETS: BASEAPI_PATH + "/assets/api/UserAssets",
  GET_ALL_COLLECTION_BY_CATEGORY:
    BASEAPI_PATH + "/assets/api/AllCollectionsofCategory",
  GET_ALL_COLLECTION: BASEAPI_PATH + "/assets/api/AllCollections",
  GET_NFT: BASEAPI_PATH + "/assets/api/getNft",
  MAKE_AUCTION_BID: BASEAPI_PATH + "/assets/api/BidAuction",
  USER_COLLECTED_ITEMS: BASEAPI_PATH + "/assets/api/UsersCollectedItems",
  PRICE_AUCTION: BASEAPI_PATH + "/assets/api/PriceAuction",
  BUY_FIAT: BASEAPI_PATH + "/assets/api/buyForUser",
  FIAT_PRICE: BASEAPI_PATH + "/assets/api/payment/getMaticPrice",
  TRENDING_NFT: BASEAPI_PATH + "/assets/api/Trending",
  UPDATE_USER_PROFILE: BASEAPI_PATH + "/account/api/updateUserProfile",
};

export const WHITELISTED_API_ROUTES = [API_ROUTES.LOGIN];

export const INFURA_PROJECT_ID = "2PvjYHIvpiYcwgVY1nXrXYf484O";
export const INFURA_PROJECT_SECRET = "b4b58a1919741d3b715f60e3bd5a324a";

export const AUCTION_ADDRESS = "0x348b7b16833246c06d1948E5f3571095cB37398c";
export const FACTORY_CONTRACT = "0x547C0Eb98EF3690897322E5d51Fd8bE215503Cc9";
export const MARKETPLACE_ADDRESS = "0x584e099D2518a1EFC409E9E8dD204e65017B80c6";
export const CHAIN_ID = 137;
