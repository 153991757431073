import Web3 from 'web3';

import ERC721_ABI from '../utils/ABIs/ERC721ABI.json';
import AUCTION_ABI from '../utils/ABIs/AuctionABI.json';
import FACTORY_ABI from '../utils/ABIs/FactoryABI.json';
import MARKETPLACE_ABI from '../utils/ABIs/MarketplaceABI.json';

export const TX_TYPE = {
  NFT: 'nft',
  MARKETPLACE: 'marketplace',
  AUCTION: 'auction',
  FACTORY: 'factory',
};

export const abiMap = {
  marketplace: MARKETPLACE_ABI,
  nft: ERC721_ABI,
  auction: AUCTION_ABI,
  factory: FACTORY_ABI,
};

export const getRpcUrl = {
  1: 'mainnet',
  11155111: 'sepolia',
  97: 'https://rpc.ankr.com/bsc_testnet_chapel/f8f470741434b01cc2e7f442b1c4fd075309fe88ec6a4b4c46c503a6992f868a',
  80002: 'https://polygon-amoy.infura.io/v3/e6ca221dbea0464996e7e3991679d916',
};

function createContractInstance(abi, address, rpcUrl) {
  const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
  return new web3.eth.Contract(abi, address);
}

export async function getEvents(tx, txType, address, chainId) {
  const rpcUrl = getRpcUrl[chainId];
  const contract = createContractInstance(abiMap[txType], address, rpcUrl);

  if (!contract) {
    throw new Error('Invalid txType provided.');
  }

  const events = await contract.getPastEvents('allEvents', {
    fromBlock: parseInt(tx.blockNumber),
    toBlock: parseInt(tx.blockNumber),
  });

  if (!events) {
    console.log('error while fetching events');
  }
  let formattedEvents = {};
  for (let event of events) {
    formattedEvents[event.event] = event.returnValues;
  }

  return formattedEvents;
}
