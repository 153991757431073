import { toast } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import Navbar from "../Navbar";
import * as API from "../../utils/api";
import ButtonLoader from "../ButtonLoader";
import { CHAIN_DATA } from "../../utils/category";
import { throwIfExists } from "../../utils/helper";
import { switchNetwork } from "../../utils/connection";
import * as BlockchainApi from "../../utils/blockchain";
import ReactImageCropper from "../Modals/ReactImageCropper";
import { useReducerPlus } from "../../utils/useReducerPlus";
import { handleFileIpfs, handleMetaDataIpfs } from "../../utils/IPFS/index";
import Card from "../Card";

const MultipleNFT = () => {
  const navigate = useNavigate();

  const { account, chainId, activate } = useWeb3React();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.currentUser);
  const chain = useSelector((state) => state.chainReducer);

  const [nftData, setNftData] = useState([]);
  const [uriData, setUriData] = useState([]);
  const [loading, setLoading] = useState(false);

  const initalState = {
    file: null,
    name: "",
    royality: "",
    description: "",
    collection: null,
    URI: "",
    metaDataURI: "",
    collectionData: [],
    loader: false,
    chain: chain,
  };
  const [state, setState] = useReducerPlus(initalState);

  const [src, setSrc] = useState(null);

  useEffect(() => {
    if (user) {
      getUserCollection(state.chain.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.chain]);

  const getUserCollection = async (chainId) => {
    try {
      const [res, error] = await API.GetUserCollection(
        account || user.wallet,
        chainId
      );
      throwIfExists(error);
      setState({
        collectionData: res.collection.length > 0 ? res.collection : null,
        collection: res.collection[0].collection_address || null,
      });
      dispatch({ type: "UPDATE_USER_COLLECTION", payload: res.collection });
    } catch (e) {
      console.log({ getUserCollection: e });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };

  const handleNetworkChange = async (e) => {
    const { value } = e.target;
    const chain = JSON.parse(value);
    try {
      setState({ collectionData: null, chain, collection: null });
      getUserCollection(chain.id);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const handleCroppedImageSubmit = async (data) => {
    const { croppedFile, croppedImageUrl } = data;
    try {
      const path = await handleFileIpfs(croppedFile);
      if (path) {
        setState({
          file: croppedFile,
          URI: path,
        });
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    const { URI, description, collection, name, royality } = state;
    const metaObj = {
      name,
      description,
      URI,
    };

    const metaDataURI = await handleMetaDataIpfs(metaObj);
    setUriData([...uriData, metaDataURI]);
    setNftData([
      ...nftData,
      {
        URI,
        metadataURI: metaDataURI,
        description,
        collection,
        name,
        earnings: royality,
        traits: "{}",
      },
    ]);
    setState({
      ...initalState,
      collectionData: state.collectionData,
      collection,
    });
  };

  console.log({ nftData });

  const handleDelete = (index) => {
    setNftData(nftData.filter((_, i) => i !== index));
  };

  const [cropModal, setCropModal] = useState(false);

  const handleMintAll = async () => {
    try {
      setLoading(true);
      const { collection, chain } = state;

      if (chain.id !== chainId) {
        await switchNetwork(dispatch, state.chain);
      }
      const [tokenRes, tokenError] = await BlockchainApi.CreateMultipleNFTs({
        collectionaddress: collection,
        URI: uriData,
        walletAddress: account,
        chainId: chain.id,
      });

      throwIfExists(tokenError);

      const updatedNftData = nftData.map((nft, index) => ({
        ...nft,
        tokenId: tokenRes.tokenIds[index], // Add tokenId from tokenRes
      }));

      const payload = {
        nftArray: updatedNftData,
      };
      const [res, nftError] = await API.CreateMultipleToken(
        account,
        payload,
        chain.id
      );

      if (nftError) throwIfExists(nftError);

      // for (const nft of updatedNftData) {
      //   const payload = {
      //     tokenId: nft.tokenId,
      //     URI: nft.metaDataURI,
      //     desc: nft.description,
      //     traits: '{}',
      //     collectionaddress: nft.collection,
      //     name: nft.name,
      //     creator: tokenRes.creator,
      //     earnings: nft.royality,
      //   };

      //   const [nftRes, nftError] = await API.CreateToken(
      //     account,
      //     payload,
      //     nft.chain.id,
      //   );

      //   if (nftError) {
      //     console.error(`Error creating token for ${nft.name}:`, nftError);
      //   } else {
      //     console.log(`Token created successfully for ${nft.name}:`, nftRes);
      //   }
      // }

      toast.success("Nfts created successfully");
      navigate(`/collections/${collection}/${state.chain.id}`);
    } catch (error) {
      console.log({ handleSubmit: error });
      toast.error(error.reason);
    } finally {
      setState({ loader: false });
    }
  };
  return (
    <>
      <div style={{ background: "linear-gradient(#26592d, #000000)" }}>
        <div className="container">
          <Navbar />
        </div>
      </div>

      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <form
            className="row create-nft-component"
            style={{ width: "100%" }}
            onSubmit={handleAdd}
          >
            <div
              className="col-lg-4 overflow-hidden create-nft-image"
              style={{
                textAlign: "center",
                width: "420px",
                height: "420px",
                borderRadius: "20px",
                overflow: "hidden",
                border: "1px dotted black",
                padding: "0",
              }}
            >
              {state.file ? (
                <img
                  src={window.URL.createObjectURL(state.file)}
                  className="dotted-border-img"
                  alt="file"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : (
                <input
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingTop: "190px",
                    paddingLeft: "40px",
                  }}
                  type="file"
                  onChange={(e) => {
                    setSrc(URL.createObjectURL(e.target.files[0]));
                    setCropModal(true);
                  }}
                  required
                />
              )}

              <ReactImageCropper
                open={cropModal}
                setOpen={setCropModal}
                selectedImage={src}
                handleCroppedImageSubmit={handleCroppedImageSubmit}
                width={1}
                height={1}
              />
            </div>
            <div className="col-lg-4">
              <div className="create-nft">
                <div className="form-group">
                  <label htmlFor="name-field">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                    className="form-control"
                    id="name-field"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="royalty">Royalty (%)</label>
                  <input
                    name="royality"
                    value={state.royality}
                    onChange={handleChange}
                    type="number"
                    className="form-control"
                    id="royalty"
                    min="0"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description-field">Description</label>
                  <textarea
                    name="description"
                    value={state.description}
                    onChange={handleChange}
                    maxLength="120"
                    className="form-control"
                    id="description-field"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="create-nft">
                <div className="form-group">
                  <label htmlFor="quantity-field">Quantity</label>
                  <input
                    type="text"
                    className="form-control"
                    id="quantity-field"
                    aria-describedby="emailHelp"
                    value={1}
                    disabled
                  />
                </div>

                <div className="form-group">
                  <div className="create-dropdown">
                    <label htmlFor="collection">Collection</label>

                    <select
                      name="collection"
                      id="collection"
                      onChange={handleChange}
                      disabled={nftData.length > 0}
                      required
                    >
                      <option disabled selected>
                        Select collection
                      </option>
                      {state.collectionData &&
                      state.collectionData.length > 0 ? (
                        state.collectionData.map((collection, index) => (
                          <option
                            value={collection.collection_address}
                            key={index}
                            selected={
                              state.collection === collection.collection_address
                            }
                          >
                            {collection.collection_name}
                          </option>
                        ))
                      ) : (
                        <option disabled selected>
                          No collection found
                        </option>
                      )}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <div className="create-dropdown">
                    <label htmlFor="chain">Select Chain</label>
                    <select
                      name="chain"
                      id="chain"
                      onChange={handleNetworkChange}
                      disabled={nftData.length > 0}
                    >
                      {CHAIN_DATA.map((chain, index) => (
                        <option
                          value={JSON.stringify(chain)}
                          key={index}
                          selected={state.chain.id === chain.id}
                          disabled={chain.id != 137}
                        >
                          {chain.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="btn save-btn"
              type="submit"
              style={{ width: "200px" }}
            >
              {state.loader ? <ButtonLoader /> : "Add"}
            </button>
          </form>
        </div>
        {nftData.length > 0 && (
          <div style={{ paddingBottom: "40px" }}>
            <p style={{ color: "black", fontSize: "28px", fontWeight: "bold" }}>
              Preview
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                gap: "20px",
              }}
            >
              {nftData.map((nft, index) => (
                <div
                  key={index}
                  style={{
                    marginTop: "25px",
                    width: "100%",
                    position: "relative",
                  }}
                  className="prc-col col-lg-3 col-md-4 col-sm-12"
                >
                  <div
                    style={{
                      background: "rgba(0, 0, 0 , 0.4)",
                      position: "absolute",
                      zIndex: "1",
                      right: "4px",
                      top: "4px",
                      width: "24px",
                      height: "24px",
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDelete(index)}
                  >
                    <CloseIcon />
                  </div>
                  <div className="card">
                    <img
                      src={nft.URI}
                      style={{
                        padding: "12px",
                        height: "280px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      className="card-img-top"
                      alt={nft.name}
                    />
                    <div className="card-body">
                      <span style={{ fontWeight: "bold" }}>{nft.name}</span>
                      <p
                        style={{
                          color: "#11111175",
                          fontSize: "small",
                          height: "40px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {nft.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <button
              className="btn save-btn"
              style={{ width: "200px" }}
              onClick={handleMintAll}
            >
              {loading ? <ButtonLoader /> : "Mint All"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default MultipleNFT;
