import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import CreateNFT from './Components/Pages/CreateNFT';
import Explore from './Components/Pages/Explore';
import NFTListing from './Components/NFTListing/NFTListing';
import Profile from './Components/Pages/Profile';
import CreateCollection from './Components/Pages/CreateCollection';
import MyCollection from './Components/Pages/MyCollection';
import CollectionDetails from './Components/Pages/CollectionsDetails';
import ListAsset from './Components/Pages/ListAsset';
import { useEffect, useState } from 'react';
import { GetTrendingNft } from '../src/utils/api';
import { throwIfExists } from '../src/utils/helper';

//WEB3
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';

//TOASTER
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

// REDUX
import { Provider } from 'react-redux';
import store from './redux/store';
import { RequireAuth } from './Components/RequireAuth';
import { Hompage } from './Components/Pages/Homepage';
import Checkout from './Components/Payment/TestCards';
import Success from './Components/Payment/Success';
import Cancel from './Components/Payment/Cancel';
import Admin from './Components/Pages/Admin';
import MultipleNFT from './Components/Pages/MultipleNFT';

function getLibrary(provider) {
  return new Web3Provider(provider);
}

const App = () => {
  const [trendingNft, setTrendingNft] = useState([]);

  const getTrendingNft = async (chainId) => {
    try {
      const [res, error] = await GetTrendingNft(chainId);
      throwIfExists(error);
      setTrendingNft(res.assets.slice(0, 8));
    } catch (error) {}
  };

  useEffect(() => {
    getTrendingNft(97);
  }, []);

  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Hompage trendingNft={trendingNft} />} />
            <Route path="/explore/:category" element={<Explore />} />
            {/* <Route path="/nftlisting" element={<NFTListing />} /> */}
            <Route
              path="/create"
              element={
                <RequireAuth>
                  <CreateNFT />
                </RequireAuth>
              }
            />
            <Route
              path="/create-multiple"
              element={
                <RequireAuth>
                  <MultipleNFT />
                </RequireAuth>
              }
            />
            <Route
              path="/createCollection"
              element={
                <RequireAuth>
                  <CreateCollection />
                </RequireAuth>
              }
            />
            <Route
              path="/marketplace/:collectionaddress/:tokenid/:networkId"
              element={<NFTListing />}
            />
            <Route
              path="/profile"
              element={
                <RequireAuth>
                  <Profile />
                </RequireAuth>
              }
            />
            <Route
              path="/collections/:collectionaddress/:networkId"
              element={
                <RequireAuth>
                  <CollectionDetails />
                </RequireAuth>
              }
            />
            <Route
              path="/mycollection"
              element={
                <RequireAuth>
                  <MyCollection />
                </RequireAuth>
              }
            />

            <Route
              path="/listAsset/:collectionaddress/:tokenid/:networkId"
              element={
                <RequireAuth>
                  <ListAsset />
                </RequireAuth>
              }
            />
            <Route path="/checkout/:id" element={<Checkout />} />
            <Route path="/checkout/:id/success" element={<Success />} />
            <Route path="/checkout/:id/cancel" element={<Cancel />} />
            <Route path="/admin" element={<Admin />} />
          </Routes>
          <ToastContainer />
        </BrowserRouter>
      </Web3ReactProvider>
    </Provider>
  );
};

export default App;
