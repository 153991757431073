import Web3 from 'web3';
import axios from 'axios';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom';

import * as API from '../utils/api';
import { injected } from '../utils/connection';
import { CHAIN_DATA } from '../utils/category';
import { BASEAPI_PATH } from '../utils/contants';
import { useReducerPlus } from '../utils/useReducerPlus';
import { ChainDetails, shortenAddress, throwIfExists } from '../utils/helper';

import logo from '../images/logo.png';

const Navbar = ({ loginModal, setLoginModal }) => {
  const [state, setState] = useReducerPlus({
    registerModal: false,
    loader: false,
    userName: '',
    email: '',
    password: '',
    walletaddress: '',
    suggestions: [],
    suggestionsnft: [],
    width: window.innerWidth,
    navbarSearch: '',
  });

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const appElement = document.getElementById('root');
  Modal.setAppElement(appElement);

  const { account, active, activate, deactivate, connector } = useWeb3React();

  const updateWidth = () => {
    setState({ width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      setDebouncedSearchTerm(state.navbarSearch);
    }, 500);

    return () => {
      clearTimeout(delayDebounce);
    };
  }, [state.navbarSearch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${BASEAPI_PATH}/assets/api/getResults?key=${state.navbarSearch}`,
        );
        setState({ suggestionsnft: response.data.results.nfts });
        setState({ suggestions: response.data.results.collections });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (debouncedSearchTerm) {
      fetchData();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const storedConnector = localStorage.getItem('connector');
    if (storedConnector) {
      activate(injected);
    }
  }, [activate]);

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length > 0 && accounts[0] !== account) {
        localStorage.removeItem('connector');
        dispatch({
          type: 'RESET_CURRENT_USER',
        });
        deactivate();
        navigate('/');
        // toast.warning("account changed");
      }
    };

    if (active && connector) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      // window.ethereum.on("chainChanged", handleAccountsChanged);
    }

    if (!user) getUserInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, account, connector]);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.currentUser);

  const navigate = useNavigate();

  const filterSearch = (e) => {
    if (e.target.value.trim() === '') {
      setState({ navbarSearch: [] });
    } else {
      setState({ navbarSearch: e.target.value });
    }
  };

  const getUserInformation = async () => {
    try {
      const [loginRes, loginError] = await API.metaskLogin({
        walletaddress: account,
      });
      if (loginError) {
        setState({ registerModal: true });
        return;
      }
      dispatch({ type: 'UPDATE_CURRENT_USER', payload: loginRes });
      setState({ loader: false });
    } catch (e) {
      console.log({ errorWhileGettingUserInfo: e });
      // console.log(e);
    }
  };

  const handleMetamaskConnect = async () => {
    setState({ loader: true });
    try {
      const web3 = new Web3(window.ethereum);
      const chainId = await web3.eth.getChainId();

      if (
        chainId === 97 ||
        chainId === 137 ||
        chainId === 33982 ||
        chainId === 97
      ) {
        await activate(injected);
        console.log('metamask connected');
        localStorage.setItem('connector', 'injected');
        dispatch({
          type: 'SET_CURRENT_CHAIN',
          payload: ChainDetails(chainId),
        });
        setLoginModal(false); // TODO
        return;
      }
      switchNetwork();
    } catch (error) {
      toast.error(error.message);
      console.log({ error });
    } finally {
      setState({ loader: false });
    }
  };

  const switchNetwork = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(97) }],
      });
      handleMetamaskConnect();
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [CHAIN_DATA[0].chainInfo],
          });
        } catch (error) {}
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      [name]: value,
    });
  };

  const handleRegister = async (e) => {
    try {
      e.preventDefault();
      const { userName, email, password } = state;
      const [registerRes, registerError] = await API.register({
        username: userName,
        email,
        password,
        walletaddress: account,
      });
      throwIfExists(registerError);
      setState({ registerModal: false });
      dispatch({ type: 'UPDATE_CURRENT_USER', payload: registerRes });
    } catch (error) {
      console.log({ handleRegister: error });
    }
  };

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      const { userName, password } = state;
      const [loginRes, loginError] = await API.login({
        username: userName,
        password,
      });
      throwIfExists(loginError);
      setLoginModal(false);
      dispatch({ type: 'UPDATE_CURRENT_USER', payload: loginRes });
    } catch (error) {
      console.log({ handleLogin: error });
    }
  };

  return (
    <nav
      style={{
        paddingTop: '18px',
        paddingBottom: '22px',
        backgroundColor: 'transparent',
      }}
      className="navbar navbar-expand-lg navbar-dark"
    >
      <div className="container-fluid">
        <img
          style={{ width: '100px', cursor: 'pointer' }}
          onClick={() => navigate('/')}
          src={logo}
          className="logo"
          alt="logo"
        />

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <input
          type="search"
          className="nav-input form-control outline-none"
          placeholder="Search NFTs, collections"
          value={state.navbarSearch}
          onChange={(e) => {
            filterSearch(e);
          }}
        />
        <div
          className="suggestions-wrapper"
          style={state.navbarSearch === '' ? { display: 'none' } : {}}
        >
          {state.suggestions && state.suggestions.length > 0 && (
            <div className="suggestions">
              <h3 className="suggestions-heading">Collections</h3>
              {state.suggestions.map((item) => (
                <div
                  key={item._id}
                  onClick={() =>
                    navigate(
                      `/collections/${item.collection_address}/${item.networkId}`,
                    )
                  }
                  className="suggestion"
                >
                  {console.log(item.collection_address)}
                  {item.collection_name}
                </div>
              ))}
            </div>
          )}

          {state.suggestionsnft && state.suggestionsnft.length > 0 && (
            <div className="suggestions">
              <h3 className="suggestions-heading">NFTs</h3>
              {state.suggestionsnft.map((item) => (
                <div
                  key={item._id}
                  onClick={() =>
                    navigate(
                      `/marketplace/${item.address}/${item.nftid}/${item.networkId}`,
                    )
                  }
                  className="suggestion"
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item">
              <NavLink to="/" className="nav-link">
                About
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink to="/explore/all" className="nav-link">
                Explore
              </NavLink>
            </li>
            <li
              className="nav-item nav-link"
              onClick={() => {
                if (!user) {
                  setLoginModal(true);
                } else {
                  navigate('/mycollection');
                }
              }}
            >
              Collection
              {/* <NavLink to="/mycollection" className="nav-link">
                Collection
              </NavLink> */}
            </li>
            <div className="dropdown" style={{ padding: '0 !important' }}>
              <button
                className="nav-item nav-link dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Mint
              </button>
              <ul className="dropdown-menu" style={{ width: '100px' }}>
                <li
                  onClick={() => navigate('/create')}
                  style={{ padding: '0 !important' }}
                >
                  <span className="dropdown-item">Single NFT</span>
                </li>

                <li
                  onClick={() => navigate('/create-multiple')}
                  style={{ padding: '0 !important' }}
                >
                  <span className="dropdown-item">Multiple NFTs</span>
                </li>
              </ul>
            </div>

            <li
              style={{ marginLeft: state.width > 990 && '13px' }}
              className="nav-item nav-link"
              onClick={() => {
                if (!user) {
                  setLoginModal(true);
                } else {
                  navigate('/create');
                }
              }}
            >
              Create
            </li>

            <li className="nav-item">
              {user && user.wallet ? (
                <div className="dropdown">
                  <button
                    className="btn nav-btn dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {state.loader ? '...' : shortenAddress(user.wallet)}
                  </button>
                  <ul className="dropdown-menu">
                    <li onClick={() => navigate('/profile')}>
                      <span className="dropdown-item">My Profile</span>
                    </li>

                    <li onClick={() => navigate('/createCollection')}>
                      <span className="dropdown-item">Create Collection</span>
                    </li>
                    {/* {!account && (
                      <li onClick={handleMetamaskConnect}>
                        <a className="dropdown-item">Connect Metamask</a>
                      </li>
                    )} */}
                    <li>
                      <button
                        className="explore-btn"
                        onClick={() => {
                          deactivate();
                          localStorage.removeItem('connector');
                          dispatch({
                            type: 'RESET_CURRENT_USER',
                          });
                        }}
                      >
                        Sign Out
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                <button
                  className="nav-btn"
                  onClick={() => {
                    // handleMetamaskConnect();
                    setLoginModal(true);
                    //   setLoginModal(true);
                    //   document.body.style.overflow = "hidden";
                  }}
                >
                  Connect Mundum
                </button>
              )}

              <Modal
                appElement={appElement}
                isOpen={loginModal}
                onRequestClose={() => {
                  setLoginModal(false);
                  document.body.style.overflow = 'auto';
                }}
                style={{
                  content: {
                    maxWidth: '500px',
                    maxHeight: '500px',
                    margin: '50px auto',
                    textAlign: 'center',
                    backgroundColor: '#ffffff',
                    color: '#111111',
                    borderRadius: '15px',
                    zIndex: '999',
                  },
                  overlay: {
                    backgroundColor: 'rgba(30, 30, 30, 0.7)',
                  },
                }}
              >
                <button
                  className="login-close-btn"
                  onClick={() => {
                    setLoginModal(false);
                    document.body.style.overflow = 'auto';
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
                <h1
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '3rem',
                  }}
                >
                  Login
                </h1>

                <form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 'small',
                  }}
                >
                  <input
                    className="login-username "
                    placeholder="Your username"
                    onChange={handleChange}
                    value={state.userName}
                    name="userName"
                    required
                  />
                  <input
                    style={{ marginBottom: '1rem' }}
                    className="login-username"
                    placeholder="Password"
                    onChange={handleChange}
                    value={state.password}
                    type="password"
                    name="password"
                    required
                  />
                  <button
                    onClick={handleLogin}
                    className="btn login-btn btn-secondary"
                  >
                    Login
                  </button>
                  <p style={{ margin: '4rem auto 0.5rem', color: 'gray' }}>
                    <u>Forget my password</u>
                  </p>
                  <p>
                    Don't have an account,{' '}
                    <u
                      style={{ color: '#28562c' }}
                      onClick={() => {
                        setState({ registerModal: true });
                        setLoginModal(false);
                      }}
                    >
                      Create now
                    </u>
                  </p>
                </form>
                <button
                  className="nav-btn login-btn"
                  style={{ color: 'black' }}
                  onClick={handleMetamaskConnect}
                >
                  Connect Metamask
                </button>
              </Modal>

              {/* REGISTER MODAL */}
              <Modal
                ariaHideApp={false}
                isOpen={state.registerModal}
                onRequestClose={() => {
                  setState({ registerModal: false });
                  document.body.style.overflow = 'auto';
                }}
                style={{
                  content: {
                    maxWidth: '500px',
                    maxHeight: '500px',
                    margin: '50px auto',
                    textAlign: 'center',
                    backgroundColor: '#ffffff',
                    color: '#111111',
                    borderRadius: '15px',
                    zIndex: '999',
                  },
                  overlay: {
                    backgroundColor: 'rgba(30, 30, 30, 0.7)',
                  },
                }}
              >
                <button
                  className="login-close-btn"
                  onClick={() => {
                    setState({ registerModal: false });
                    deactivate();
                    document.body.style.overflow = 'auto';
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
                <h1
                  style={{
                    fontSize: 'medium',
                    fontWeight: 'bold',
                    marginTop: '1rem',
                  }}
                >
                  Register
                </h1>

                <form
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: 'small',
                  }}
                >
                  <input
                    className="login-username "
                    placeholder="Username"
                    onChange={handleChange}
                    value={state.userName}
                    name="userName"
                    required
                  />
                  <input
                    className="login-username"
                    placeholder="Email"
                    onChange={handleChange}
                    value={state.email}
                    name="email"
                    required
                  />
                  <input
                    className="login-username"
                    placeholder="Password"
                    type="password"
                    onChange={handleChange}
                    value={state.password}
                    name="password"
                    required
                  />
                  <input
                    className="login-username"
                    placeholder="Wallet Address"
                    onChange={handleChange}
                    name="walletAddress"
                    disabled={account}
                    value={account ? account : ''}
                  />
                  <button
                    className="btn login-btn btn-secondary"
                    style={{ marginTop: '1rem' }}
                    onClick={handleRegister}
                  >
                    Register
                  </button>
                  <p>
                    <u
                      style={{
                        color: '#28562c',
                        top: '3rem',
                        position: 'relative',
                      }}
                      onClick={() => {
                        setState({ registerModal: false });
                        setLoginModal(true);
                      }}
                    >
                      Already have an account?
                    </u>
                  </p>
                </form>
              </Modal>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
